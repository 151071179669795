import React from 'react';
import Layout from '../../containers/gatsby/layout';
import Credibility from '../../containers/sections/Credibility/Credibility';
import ServiceBlurb from '../../containers/services/ServiceBlurb/ServiceBlurb';
import ServiceFAQs from '../../containers/services/ServiceFAQs/ServiceFAQs';
import BookOnlineContainer from '../../containers/sections/BookOnline/BookOnlineContainer';
import ServicesGallery from '../../containers/services/ServicesGallery/ServicesGallery';
import SEO from '../../containers/gatsby/seo';
import ServiceHeroContainer from '../../containers/heroes/ServiceHero/ServiceHeroContainer';

const PickupFromStorePage = (props) => (
  <Layout overlayHeader={true} location={props.location}>
    <SEO title="Collection & Devliery Service from IKEA, Homebase, Gumtree, B&Q, and more" keywords={[`IKEA delivery`, `store collection london`, `IKEA london`, `how much does IKEA delivery cost`, `pickup service london`, `collect and deliver furniture london`, `Gumtree man and van`, `man and van Gumtree`]} />
    <ServiceHeroContainer
      jobName={'Store Pickup'}
      title={'Pickup from Store'}
      description={'Customers use JJD to pickup items from IKEA, eBay, Homebase, Gumtree & more - everyday'}
      heroImg={require('../../assets/services-heroes/ikea.jpg')}
      reviewImage={require('../../assets/avatars/4.png')}
      reviewContent={'Service was absolutely amazing. Bernard helped us in everything he could to get our furniture from IKEA to our new flat and he was all time informing when he would arrive. Quick answers to your questions, great quote and great service ... I would definitely recommend JJD and I will use it again in the future'}
      reviewName={'Sarah Click'}
    />
    <Credibility />
    <ServiceBlurb
      blurbTitle={'Get stuff picked up and delivered from IKEA, eBay, Homebase, or Gumtree right to your door'}
      blurb={'Just bought a new piece of furniture? You don\'t have to wait weeks or months to have it delivered. We can pick it up straight from the store to your door.'}
      blurb2={'We just need a destination point to deliver the item to, and an address of the store where your item is. A JJD professional will get it to you quicker and cheaper than the bigger companies.'}
      blurb3={'A lot of online retailers are poor at delivery services because it\'s hard to schedule deliveries when they depend on how many products they sell, which changes drastically. That\'s where JJD comes in. Customers are quickly turning to rely on JJD to get things delivered from big stores on time, and on any day that suits you.'}
    />
    <ServicesGallery
      gallerySubtitle={'Don\'t want to wait weeks for your new sofa to arrive? JJD is here to help'}
      photos={[
        require('../../assets/service-gallery/1.jpg'),
        require('../../assets/service-gallery/2.png'),
        require('../../assets/service-gallery/3.jpg'),
        require('../../assets/service-gallery/4.jpg'),
        require('../../assets/service-gallery/5.jpg'),
        require('../../assets/service-gallery/6.jpg'),
        require('../../assets/service-gallery/8.jpg'),
        require('../../assets/service-gallery/9.png'),
        require('../../assets/service-gallery/10.png'),
        require('../../assets/service-gallery/11.jpg'),
        require('../../assets/service-gallery/12.jpg'),
        require('../../assets/service-gallery/13.jpg'),
        require('../../assets/service-gallery/14.jpg'),
        require('../../assets/service-gallery/15.jpg'),
        require('../../assets/service-gallery/16.jpg'),
        require('../../assets/service-gallery/17.jpg'),
        require('../../assets/service-gallery/18.jpg'),
        require('../../assets/service-gallery/19.jpg'),
        require('../../assets/service-gallery/20.jpg'),
        require('../../assets/service-gallery/21.jpeg'),
        require('../../assets/service-gallery/22.jpeg'),
        require('../../assets/service-gallery/23.jpeg'),
      ]}
    />
    <ServiceFAQs
      faqTitle={'FAQs'}
      faqSubTitle={'Got a question store pickups?'}
      faqs={[
        {
          question: 'Do you deliver on weekends?',
          answer: 'Yes, and any day during the week - at anytime. JJD is a available 24/7 for any man-and-van service. Just choose a pickup date and time when you book. It\'s that simple.'
        },
        {
          question: 'Do I need to be at the store to get something picked up?',
          answer: 'No, but you will need to provide our driver with the right information and your phone number to verify that items can be picked up and delivered to you on time.'
        },
        {
          question: 'What stores do you do pickup and deliveries from?',
          answer: 'We do 24/7 store pickup and deliveries from IKEA, Homebase, eBay, Gumtree, Shpock, B&Q, Wickes, Currys, Argos, and more. We can get things delivered to you today, tomorrow - or whenever suits you.'
        },
        {
          question: 'How much does IKEA delivery cost?',
          answer: 'Depending on item size and when you want it delivered, IKEA charges anywhere from £29 to £53. JJD charges about the same price, however, same-day deliveries at IKEA and other major stores are not always available. And it can take weeks, or even months, to get stuff delivered to your door. JJD is available 24/7 and we can get anything delivered to you today.'
        }
      ]}
    />
    <BookOnlineContainer />
  </Layout>
);

export default PickupFromStorePage;
